import { Card, Help, InfoBox, Pagination, Table, useGet } from '@enkla-gui'
import React, { useContext } from 'react'

import Calendar from './calendar'
import Greeting from '@components/layout/Greeting'
import { UserContext } from '@/App'
import { icons } from '@config'
import { tickethelper } from '@/_helpers'
import Upcoming from '../../components/help/upcoming'

export default function Dashboard() {
	const { user, billable } = useContext(UserContext)
	const { data: projected } = useGet({ endpoint: 'api/economy/projected' })
	const { data: tickets, setPage } = useGet({ endpoint: 'api/ticket', filter: { status: 'new' } })
	const { data: tasks } = useGet({ endpoint: 'api/task' })

	return (
		<>
			<Greeting />
			{user.show_onboarding_in_sidebar && <Help header={false} type='primary' icon={null}>
				<h5>Nu kör vi! 🚀</h5>
				<p class='mb-0'>
				Varmt välkommen till Enkla Fastigheter – tack för att du valt att prova oss! Din resa mot en enklare och effektivare fastighetsförvaltning tar nu fart.<br />
				Klicka på <b>Kom igång!</b> i sidomenyn för att följa vår steg-för-steg-guide och snabbt lära dig allt du behöver för att komma igång med systemet.<br/><br/>
				Under din 30-dagars gratis provperiod kan du utforska alla funktioner i din egen takt. Vi finns här för att stötta dig längs vägen, så tveka inte att höra av dig om du har några frågor.<br />

				<small style={{fontSize: 10}}>(Du kan alltid kontakta oss under "Hjälp" i sidomenyn)</small>
				</p>
			</Help>}

			<div class="row">
				<div class="col-xl-4 col-lg-12">
					<InfoBox
						link={{ href: '#!/entity/type/vacancies', name: 'Visa' }}
						loading={!billable}
						title='Vakanser'
						subtitle={billable.stat_vacancies}
						icon={icons.vacancies}
					/>
					<InfoBox
						link={{ href: '#!/lease/filter/ending', name: 'Visa' }}
						loading={!billable}
						title='Upphörande avtal'
						subtitle={billable.stat_ends_month}
						icon={icons.clock}
					/>
					<InfoBox
						link={{ href: '#!/invoicebase/status/review', name: 'Visa' }}
						loading={!billable}
						title='Avier att granska'
						subtitle={billable?.stat_not_sent_invoices}
						icon={icons.inbox}
					/>
					<InfoBox
						link={{ href: '#!/invoicebase/status/overdue', name: 'Visa' }}
						loading={!billable}
						title='Förfallna avier'
						subtitle={billable?.stat_overdue_invoices}
						icon={icons.warning}
					/>
				</div>
				<div class="col-xl-8 col-lg-12">
					<Card title='Nya ärenden' icon={icons.ticket}>
						<Table
							rows={tickets?.data}
							empty={{ text: 'Just nu har du inga nya ärenden att hantera – allt verkar vara under kontroll!', icon: icons.ticket }}
							link={`/#!/ticket/@{id}`}
							functions={{
								getStatus: status => <span class={`badge w-100 bg-${tickethelper.status(status).color}`}>{tickethelper.status(status).label}</span>,
								getType: type => <span>{tickethelper.type(type)}</span>,
								tenant: ticket =>
									ticket.tenant ? (
										<a class='text-decoration-none' onClick={e => e.stopPropagation()} href={`/#!/tenant/${ticket.tenant.id}/`}>
											{' '}
											{ticket.tenant.name}
										</a>
									) : (
										''
									)
							}}
							keys={['status>function:getStatus', 'type>function:getType', 'subject', '_this>function:tenant']}>
							<th class='min'>Status</th>
							<th class='min'>Ärendetyp</th>
							<th>Ämne</th>
							<th class='min'>Hyresgäst</th>
						</Table>
						<Pagination data={tickets} fetch={setPage} />
					</Card>
					<Upcoming />
				</div>
			</div>
		</>
	)
}
