import React, { useEffect, useState } from 'react'
import { notify, integrationhelper } from '@/_helpers'
import { API, ViewHeader, Card, Table, Submit, Input, Help } from '@enkla-gui'
import { useForm } from 'react-hook-form'
import Modal from 'react-modal'

export default function Integration() {
	const [fasDokModal, setFasDokModal] = useState(false)
	const [sending, setSending] = useState(false)
	const [error, setError] = useState(null)

	const { register: fasDokRegister, handleSubmit: fasDokSubmit } = useForm()

	const connectFasDok = data => {
		setSending(true)
		API.post(`api/integration/fasdok/connect`, data)
			.then(res => {
				notify.toast('Integration skapad')
				setFasDokModal(false)
				window.location.hash = '/integration/overview'
				setSending(false)
			})
			.catch(([err, res]) => {
				setError({ data: { access_token: [res.message] } })
				setSending(false)
			})
	}

	return (
		<>
			<ViewHeader
				icon='fa-plug'
				title='Integrationer'
				subtitle='Anslut'
			/>
			<div class="row">
				<div class="col-6">
					<Card
						buttons={{
							footer: [{ callback: () => API.get('api/fortnox/login').then(res => (window.location.href = res)), text: 'Anslut', bg: 'light' }]
						}}>
						<div class="row">
							<div class="bicflex">
								<img style={{ 'width': '50%', 'padding': '50px 0px' }} src='images/integrations/fortnox.svg' />
							</div>
						</div>
						<section>
							<p>Genom att ansluta Fortnox till vårt system kan du automatisera hanteringen av hyresavier och betalningar.
								Integration med Fortnox gör det möjligt att:</p>
							<ul>
								<li>Skapa och skicka hyresavier direkt från systemet.</li>
								<li>Automatiskt hålla koll på betalstatus och uppdatera information i realtid.</li>
								<li>Synkronisera kunduppgifter för en smidig faktureringsprocess.</li>
							</ul>
							<p>För att aktivera integrationen kommer du att omdirigeras till Fortnox portal där du behöver godkänna anslutningen.
								Vårt system kommer att begära behörighet att läsa och skriva fakturor samt hantera kunduppgifter för att säkerställa
								att all information är korrekt och uppdaterad.</p>
						</section>
					</Card>
				</div>
				<div class="col-6">
					<Card
						buttons={{
							footer: [{ callback: () => API.get('api/visma/login').then(res => (window.location.href = res)), text: 'Anslut', bg: 'light' }]
						}}>
						<div class="row">
							<div class="bicflex">
								<img style={{ 'width': '50%', 'padding': '50px 0px' }} src='images/integrations/visma.svg' />
							</div>
						</div>
						<section>
							<p>Vill du automatisera faktureringen och få bättre kontroll över betalningar? Genom att koppla vårt system till Visma kan du:</p>
							<ul>
								<li>Generera och skicka hyresavier direkt från plattformen.</li>
								<li>Automatiskt hålla dina fakturor och betalningar uppdaterade.</li>
								<li>Synkronisera kunduppgifter för en enkel och säker hantering.</li>
							</ul>
							<p>För att aktivera integrationen kommer du att skickas till Visma där du godkänner anslutningen. Vårt system behöver behörighet att läsa och skriva fakturor samt hantera kunduppgifter för att säkerställa att all information hanteras korrekt.</p>
						</section>
					</Card>
				</div>
			</div>
			<div class="row">
				<div class="col-6">
					<Card
						buttons={{
							footer: [{ callback: () => setFasDokModal(true), text: 'Anslut', bg: 'light' }]
						}}>

						<div class="row">
							<div class="bicflex">
								<img style={{ 'padding': '50px 0px' }} src='images/integrations/fastdok.svg' />
							</div>
						</div>
						<section>
							<p>Genom att integrera Fastighetsägarna Dokument med vårt system kan du hantera dina hyresavtal och andra viktiga dokument digitalt. Med denna koppling kan du:</p>
							<ul>
								<li>Skapa och hantera avtal direkt i systemet.</li>
								<li>Automatiskt fylla i uppgifter från din kunddatabas.</li>
								<li>Hålla alla dokument samlade på ett ställe för enkel åtkomst.</li>
							</ul>
							<p>För att aktivera integrationen behöver du ange din API-nyckel från Fastighetsägarna. Du hittar mer information om var du hämtar din API-nyckel på
								<a target='_blank' href='https://kundo.se/org/fastighetsagarna/d/var-hittar-jag-api-nyckeln/' rel="noreferrer">
									Fastighetsägarnas kundforum
								</a>.
							</p>
						</section>
					</Card>
				</div>
			</div>
			<Modal isOpen={fasDokModal} onRequestClose={() => setFasDokModal(false)} className='no-padding'>
				<Card
					title='Koppla Fastighetsägarna Dokument'
					icon='fas fa-fw fa-gavel'
					buttons={{
						header: [{ callback: () => setFasDokModal(false), icon: 'fas fa-times', text: 'Avbryt', bg: 'light' }],
						footer: [<Submit onSubmit={fasDokSubmit(connectFasDok)} text='Koppla' sending={sending} />]
					}}>
					<div class='row mb-3'>
						<Input controls={{ register: fasDokRegister }} name='access_token' options={{ required: true }} label='API-nyckel' error={error} />
					</div>
				</Card>
			</Modal>
		</>
	)
}

