import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Modal from 'react-modal'
import { notify } from '@/_helpers'
import { useParams } from 'react-router-dom'
import { API, Card, Table, Pagination, Submit, Input, TextArea } from '@enkla-gui'

export default function Files(props) {
  const { register, handleSubmit, reset } = useForm()
  const { register: updateRegister, handleSubmit: updateSubmit, reset: updateReset, watch, getValues } = useForm()
  const [error, setError] = useState(null)
  const [files, setFiles] = useState(null)
  const [modal, setModal] = useState(false)
  const [sending, setSending] = useState(false)
  let { page } = useParams()

  useEffect(() => {
    if (props.id === undefined) return
    fetchFiles()
  }, [props.id])

  const fetchFiles = async (p = page || 1) => {
    API.get(`api/file/${props.type}/${props.id}`, { page: p }).then(files => setFiles(files))
  }

  const upload = data => {
    setSending(true)
    var formData = new FormData()
    formData.append('file', data.file[0])
    formData.append('alias', data.alias)
    formData.append('notice', data.notice)
    formData.append('fileable_id', props.id)
    formData.append('fileable_type', props.type)

    API.upload('api/file', formData)
      .then(res => {
        notify.toast(res.message)
        reset()
        fetchFiles()
        setModal(false)
        setSending(false)
        setError(null)
        updateReset({})
      })
      .catch(([err, res]) => {
        notify.toast(res.message, 'error')
        setError({
          message: res.message,
          data: res.data
        })
        setSending(false)
      })
  }

  const remove = async id => {
    if (!(await notify.confirm())) return
    API.delete(`api/file/${id}`)
      .then(res => {
        notify.toast(`Fil borttagen`)
        fetchFiles()
        updateReset({})
      })
      .catch(([err, res]) => {
        notify.toast(`Borttagningen misslyckades: ${res.message}`, 'error')
      })
  }
  const update = data => {
    setSending(true)
    API.patch('api/file', {
      ...data
    })
      .then(res => {
        notify.toast('Ändringar sparade')
        fetchFiles()
        setSending(false)
        updateReset({})
        setError(null)
      })
      .catch(([err, res]) => {
        notify.toast(res.message, 'error')
        setSending(false)
        setError({
          message: res.message,
          data: res.data
        })
      })
  }

  const download = file => {
    API.get(`api/file/${file.id}`, {})
      .then(res => {
        var link = document.createElement('a')
        link.href = res
        link.target = '_blank'
        link.click()
      })
      .catch(([err, res]) => {
        notify.toast(res.message, 'error')
        setSending(false)
        setError({
          message: res.message,
          data: res.data
        })
      })
  }

  return (
    <>
      <Card
        title='Filer'
        icon='fas fa-fw fa-server'
        buttons={{
          header: [{ callback: () => setModal(true), icon: 'fas fa-upload text-light', text: 'Ladda upp' }]
        }}>
        <Table
          rows={files?.data}
          empty={{ text: 'Det finns inte några filer', icon: 'fas fa-server' }}
          functions={{
            download: file => download(file),
            show: file => updateReset(file)
          }}
          keys={['_this>button:show:fa-edit', 'name', 'notice', 'size', 'created_at>date:full', '_this>button:download:fa-download']}>
          <th class='min' />
          <th>Filnamn</th>
          <th>Beskrivning</th>
          <th>Storlek</th>
          <th>Uppladdat</th>
          <th class='min' />
        </Table>
        <Pagination data={files} fetch={fetchFiles} />
      </Card>
      <Modal isOpen={modal} onRequestClose={() => setModal(false)} className='no-padding'>
        <Card
          title='Ladda upp fil'
          icon='fas fa-fw fa-upload'
          buttons={{
            header: [{ callback: () => setModal(false), icon: 'fas fa-times', text: 'Avbryt', bg: 'light' }],
            footer: [<Submit onSubmit={handleSubmit(upload)} text='Ladda upp' sending={sending} />]
          }}>
          <div class='mb-3 form-group'>
            <label>Fil</label>
            <input {...register('file', { required: true })} class='form-control' type='file' name='file' />
            {error?.data?.file && (
              <div class='form-inline-error'>
                <i class='fa fa-fw fa-exclamation-triangle' /> {error.data.file.map(err => `${err}, `)}{' '}
              </div>
            )}
          </div>
          <div class='mb-3 form-group'>
            <label>Beskrivning</label>
            <textarea rows={5} {...register('notice', { required: false })} type='text' class='form-control' />
            {error?.data?.notice && (
              <div class='form-inline-error'>
                <i class='fa fa-fw fa-exclamation-triangle' /> {error.data.notice[0]}{' '}
              </div>
            )}
          </div>
        </Card>
      </Modal>
      <Modal isOpen={watch('id') !== undefined} onRequestClose={() => updateReset({})} className='no-padding'>
        <Card
          title='Fil-information'
          icon='fas fa-fw fa-server'
          buttons={{
            header: [{ callback: () => updateReset({}), icon: 'fas fa-times', text: 'Avbryt', bg: 'light' }],
            footer: [
              { callback: () => remove(getValues('id')), icon: 'fas fa-times', text: 'Radera', bg: 'danger text-white' },
              <Submit onSubmit={updateSubmit(update)} text='Uppdatera' sending={sending} />
            ]
          }}>
          <div class='mb-3'>
            <Input controls={{ register: updateRegister }} disabled name='name' label='Filnamn' />
          </div>
          <div class='mb-3'>
            <Input controls={{ register: updateRegister }} disabled name='size' label='Storlek' />
          </div>
          <div class='mb-3'>
            <Input controls={{ register: updateRegister }} disabled name='mimetype' label='Mimetype' />
          </div>
          <div class='mb-3'>
            <TextArea rows='4' controls={{ register: updateRegister }} name='notice' label='Beskrivning' error={error} />
          </div>
        </Card>
      </Modal>
    </>
  )
}
