import { API, Card, Date, InfoBox, Input, Output, Select, Submit, ViewHeader } from '@enkla-gui'
import React, { useEffect, useState } from 'react'
import { date, invoicebasehelper, notify } from '@/_helpers'
import { useHistory, useParams } from 'react-router-dom'

import Events from '@components/tools/Events'
import InvoiceRows from '@components/invoicebase/InvoiceRows'
import Modal from 'react-modal'
import { billable } from '@/_config'
import { useForm } from 'react-hook-form'

export default function InvoicebaseEdit() {
  const [sending, setSending] = useState(false)
  const [downloading, setDownloading] = useState(false)
  const [invoicebase, setInvoicebase] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [error, setError] = useState(false)
  const [generateModal, setGenerateModal] = useState(false)
  const [paymentModal, setPaymentModal] = useState(false)
  const [dueAtModal, setDueAtModal] = useState(false)
  const { handleSubmit, control, register, setValue } = useForm()
  const history = useHistory()
  const { id } = useParams()

  useEffect(() => {
    getInvoicebase()
  }, [id])

  const getInvoicebase = () => {
    API.get(`api/invoicebase/${id}`)
      .then(invoicebase => {
        setInvoicebase(invoicebase)
      })
      .catch(async ([err, res]) => {
        if (res.type === 'InvoiceIntegrationException') {
          await notify.request(
            `Denna hyresavi är skapad mot en integration (${res.invoicebase.invoice_type}) som inte längre är aktiv. Du kan ansluta integrationen på nytt eller konvertera till MANUELL hyresavi.`,
            async () => {
              await API.post(`api/invoicebase/${id}/convert`)
                .then(res => {
                  notify.toast('Hyresavin har konverterats till en manuell')
                  getInvoicebase()
                })
                .catch(([err, res]) => {
                  notify.toast(`Något gick fel: ${res.message}`, 'error')
                })
            },
            'Konvertera till MANUELL'
          )
        }
      })
    setRefresh(date.now())
  }

  const remove = async () => {
    if (!(await notify.confirm())) return
    API.delete(`api/invoicebase/${id}`)
      .then(res => {
        notify.toast(`Underlaget har raderats`)
        history.push(`/lease/${invoicebase.lease.id}`)
      })
      .catch(([err, res]) => {
        notify.toast(`Borttagningen misslyckades: ${res.message}`, 'error')
      })
  }

  const cancel = async (message = '') => {
    if (!(await notify.confirm(message))) return

    API.post(`api/invoice/${invoicebase.invoice_type.toLowerCase()}/${invoicebase.id}/cancel`)
      .then(res => {
        notify.toast(`Underlaget har makulerats`)
        getInvoicebase()
      })
      .catch(([err, res]) => {
        notify.toast(`Något gick fel: ${res.message}`, 'error')
      })
  }
  const ready = async () => {
    API.patch('api/invoicebase', {
      ...invoicebase,
      status: 'READY'
    })
      .then(res => {
        notify.toast(`Underlaget har markerats som redo att skickas`)
        getInvoicebase()
      })
      .catch(([err, res]) => {
        notify.toast(`Något gick fel: ${res.message}`, 'error')
      })
  }
  const credit = async (message = '') => {
    if (!(await notify.confirm(message))) return
    console.log()
    API.post(`api/invoice/${invoicebase.invoice_type.toLowerCase()}/${invoicebase.id}/credit`)
      .then(res => {
        notify.toast(`Underlaget har krediterats`)
        getInvoicebase()
      })
      .catch(([err, res]) => {
        notify.toast(`Något gick fel: ${res.message}`, 'error')
      })
  }

  const generateFortnox = async () => {
    await notify.request(`Vill du skapa en hyresavi i Fortnox?`, async () => {
      await API.post(`api/invoice/fortnox/${invoicebase.id}/generate`)
        .then(res => {
          notify.toast('Hyresavi skapad')
          getInvoicebase()
        })
        .catch(([err, res]) => {
          notify.toast(`Något gick fel: ${res.message}`, 'error')
        })
    })
  }
  const generateVisma = async () => {
    await notify.request(`Vill du skapa en hyresavi i Visma?`, async () => {
      await API.post(`api/invoice/visma/${invoicebase.id}/generate`)
        .then(res => {
          notify.toast('Hyresavi skapad')
          getInvoicebase()
        })
        .catch(([err, res]) => {
          notify.toast(`Något gick fel: ${res.message}`, 'error')
        })
    })
  }
  const generateManual = data => {
    API.post(`api/invoice/manual/${invoicebase.id}/generate`, data)
      .then(res => {
        notify.toast('Hyresavi skapad')
        getInvoicebase()
        setGenerateModal(false)
      })
      .catch(([err, res]) => {
        setError({
          message: res.message,
          data: res.data
        })
        notify.toast(`Något gick fel: ${res.message}`, 'error')
      })
  }
  const mail = async () => {
    await notify.request(`Vill du maila hyresavin till kund?`, async () => {
      await API.post(`api/invoice/${invoicebase.invoice_type.toLowerCase()}/${invoicebase.id}/mail`)
        .then(res => {
          notify.toast('Hyresavin har skickats via e-post')
          getInvoicebase()
        })
        .catch(([err, res]) => {
          notify.toast(`Något gick fel: ${res.message}`, 'error')
        })
    })
  }
  const einvoice = async () => {
    if (!(await notify.confirm())) return

    setSending(true)
    API.post(`api/invoice/${invoicebase.invoice_type.toLowerCase()}/${invoicebase.id}/einvoice`)
      .then(res => {
        notify.toast('Hyresavin skickad som e-faktura')
        getInvoicebase()
        setSending(false)
      })
      .catch(([err, res]) => {
        notify.toast(`Något gick fel: ${res.message}`, 'error')
        setSending(false)
      })
  }
  const bookkeep = async (message = '') => {
    if (!(await notify.confirm(message))) return

    setSending(true)
    API.post(`api/invoice/${invoicebase.invoice_type.toLowerCase()}/${invoicebase.id}/bookkeep`)
      .then(res => {
        notify.toast('Hyresavin har bokförts')
        getInvoicebase()
        setSending(false)
      })
      .catch(([err, res]) => {
        notify.toast(`Något gick fel: ${res.message}`, 'error')
        setSending(false)
      })
  }
  const changeDueAt = data => {
    setSending(true)
    API.patch(`api/invoice/${invoicebase.invoice_type.toLowerCase()}/${invoicebase.id}/dueat`, data)
      .then(res => {
        notify.toast('Förfallodatum har uppdaterats')
        getInvoicebase()
        setSending(false)
        setDueAtModal(false)
      })
      .catch(([err, res]) => {
        notify.toast(`Något gick fel: ${res.message}`, 'error')
        setSending(false)
      })
  }
  const pay = data => {
    setSending(true)
    API.post(`api/invoice/${invoicebase.invoice_type.toLowerCase()}/${invoicebase.id}/pay`, data)
      .then(res => {
        notify.toast('Betalning har uppdaterats')
        getInvoicebase()
        setSending(false)
        setPaymentModal(false)
      })
      .catch(([err, res]) => {
        notify.toast(`Något gick fel: ${res.message}`, 'error')
        setSending(false)
      })
  }

  const preview = async () => {
    setDownloading(true)
    API.post(`api/invoice/${invoicebase.invoice_type.toLowerCase()}/${invoicebase.id}/preview`)
      .then(res => {
        const linkSource = `data:application/pdf;base64,${res.file}`
        const downloadLink = document.createElement('a')
        const fileName = `Hyresavi_${invoicebase.reference}.pdf`

        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
        setDownloading(false)
      })
      .catch(([err, res]) => {
        notify.toast(`Något gick fel: ${res.message}`, 'error')
        setDownloading(false)
      })
  }

  const download = () => {
    API.get(`api/file/${invoicebase.file.id}`, {})
      .then(res => {
        var link = document.createElement('a')
        link.href = res
        link.target = '_blank'
        link.click()
      })
      .catch(([err, res]) => {
        notify.toast(res.message, 'error')
        setSending(false)
        setError({
          message: res.message,
          data: res.data
        })
      })
  }

  const renderButtons = () => {
    switch (invoicebase.invoice_type) {
      case 'FORTNOX':
        return [
          {
            title: 'Skicka',
            hidden: invoicebase.status !== 'READY',
            dropdown: true,
            sending: sending,
            icon: 'fas fa-paper-plane',
            options: [
              { title: 'Skicka till kund (e-post)', icon: 'fas fa-envelope', callback: () => mail() },
              { title: 'Skicka till kund (e-faktura)', icon: 'fas fa-file-invoice', callback: () => einvoice() }
            ]
          },
          {
            title: 'Hantera',
            dropdown: true,
            sending: sending,
            icon: 'fas fa-tools',
            options: [
              { title: 'Bokför', icon: 'fas fa-file-invoice', callback: () => bookkeep() },
              { title: 'Kreditera', icon: 'fas fa-credit-card', callback: () => credit() },
              { title: 'Makulera', icon: 'fas fa-ban', callback: () => cancel() },
              {
                title: 'Justera förfallodatum',
                icon: 'fa-calendar-day',
                hidden: ['REVIEW', 'EDIT', 'CANCELLED', 'CREDITED'].includes(invoicebase.status),
                callback: () => setDueAtModal(true)
              }
            ]
          },
          { loading: downloading, title: 'Förhandsgranska', icon: 'fas fa-file-pdf', callback: () => preview(), hidden: invoicebase.file },
          { loading: downloading, title: 'Ladda ner hyresavi (PDF)', icon: 'fas fa-file-pdf', callback: () => download(), hidden: !invoicebase.file }
        ]
      case 'VISMA':
        return [
          {
            title: 'Skicka',
            hidden: invoicebase.status !== 'READY',
            dropdown: true,
            sending: sending,
            icon: 'fas fa-paper-plane',
            options: [{ title: 'Skicka till kund (e-post)', icon: 'fas fa-envelope', callback: () => mail() }]
          },
          { loading: downloading, title: 'Förhandsgranska', icon: 'fas fa-file-pdf', callback: () => preview(), hidden: invoicebase.file },
          { loading: downloading, title: 'Ladda ner hyresavi (PDF)', icon: 'fas fa-file-pdf', callback: () => download(), hidden: !invoicebase.file }
        ]
      case 'MANUAL':
        return [
          {
            title: 'Skicka',
            hidden: invoicebase.status !== 'READY',
            dropdown: true,
            icon: 'fas fa-paper-plane',
            options: [{ title: 'Skicka till kund (e-post)', icon: 'fas fa-envelope', callback: () => mail() }]
          },
          {
            title: 'Hantera',
            dropdown: true,
            sending: sending,
            icon: 'fas fa-tools',
            options: [
              {
                title: 'Bokför',
                icon: 'fas fa-file-invoice',
                callback: () => bookkeep('OBS! Markerar enbart som bokförd i systemet och har ingen effekt på hyresavin')
              },
              {
                title: 'Kreditera',
                icon: 'fas fa-credit-card',
                callback: () => credit('OBS! Ändrar enbart status till Krediterad i systemet och har ingen effekt på hyresavin')
              },
              {
                title: 'Makulera',
                icon: 'fas fa-ban',
                callback: () => cancel('OBS! Ändrar enbart status till Makulerad i systemet och har ingen effekt på hyresavin')
              },
              {
                title: 'Justera förfallodatum',
                icon: 'fa-calendar-day',
                hidden: ['REVIEW', 'EDIT', 'CANCELLED', 'CREDITED'].includes(invoicebase.status),
                callback: () => setDueAtModal(true)
              },
              { title: 'Registrera betalning', icon: 'fas fa-money-bill', callback: () => setPaymentModal(true) }
            ]
          },
          { loading: downloading, title: 'Ladda ner hyresavi (PDF)', icon: 'fas fa-file-pdf', callback: () => download(), hidden: !invoicebase.file },
          { loading: downloading, title: 'Förhandsgranska', icon: 'fas fa-file-pdf', callback: () => preview(), hidden: invoicebase.file }
        ]
      default:
        return []
    }
  }
  return (
    <>
      <ViewHeader
        loading={!invoicebase}
        title={invoicebase?.title}
        icon={invoicebase?.invoice_type === null ? 'fa-pen-square' : 'fa-file-invoice'}
        subtitle={invoicebase?.reference}
        breadcrumbs={invoicebase?.breadcrumb}
        badges={[{ bg: invoicebasehelper.status(invoicebase?.status)?.color, text: invoicebasehelper.status(invoicebase?.status)?.label }]}
        buttons={[
          {
            title: 'Generera hyresavi',
            dropdown: true,
            hidden: invoicebase?.invoice_type !== null,
            options: [
              { title: 'Skapa i Fortnox', icon: 'fas fa-file-invoice-dollar', callback: () => generateFortnox() },
              { title: 'Skapa i Visma', icon: 'fas fa-file-invoice-dollar', callback: () => generateVisma() },
              { title: 'Manuell hyresavi', icon: 'fas fa-inbox', callback: () => setGenerateModal(true) }
            ]
          },
          {
            hidden: invoicebase?.invoice_type !== null,
            position: 'right',
            icon: 'fa-trash',
            title: 'Radera',
            callback: () => remove(),
          },
          {
            hidden: invoicebase.status !== 'REVIEW',
            icon: 'fa-check',
            title: 'Markera som redo att skickas',
            callback: () => ready(),
            color: 'success bg-success'
          }
        ].concat(...renderButtons())}
      />
      {invoicebase?.invoice_type !== null && (
        <div class='row'>
          <InfoBox
            loading={!invoicebase}
            title='Skickad till kund'
            subtitle={invoicebase?.sent_at === null ? 'Ej skickad' : date.full(invoicebase?.sent_at)}
            icon='fa-paper-plane'
          />
          <InfoBox
            loading={!invoicebase}
            title='Hyresavi bokförd'
            subtitle={invoicebase.booked_at === null ? 'Ej bokförd' : date.full(invoicebase.booked_at)}
            icon='fa-book'
          />
        </div>
      )}
      <Card loading={!invoicebase} title='Information' icon='fas fa-info-circle'>
        <div class='row mb-3'>
          <Output type='currency' currency={billable.currency} value={invoicebase.fee + billable.currency} label='Totalbelopp' />
          <Output type='currency' currency={billable.currency} value={invoicebase.tax + billable.currency} label='Moms' />
          {invoicebase.payment_left !== null && (
            <Output
              type='currency'
              value={invoicebase.payment_left + billable.currency}
              background={invoicebase.payment_left <= 0 ? '#a3fbce' : '#ff6a7378'}
              label='Kvar att betala'
            />
          )}
        </div>
        {invoicebase.invoice_type !== null && (
          <div class='row mb-3'>
            <Output value={invoicebase.reference} label='Referens' />
            {invoicebase.ocr !== null && <Output value={invoicebase.ocr} label='OCR' />}
          </div>
        )}
        <div class='row mb-3'>
          <Output value={date.short(invoicebase.start) + ' - ' + date.short(invoicebase.stop)} label='Avser Period' />
          <Output value={invoicebase.due_at ? date.short(invoicebase.due_at) : 'Hyresavi ej genererad'} label='Förfaller' />
        </div>
      </Card>
      {invoicebase.tenant && (
        <Card
          loading={!invoicebase}
          title='Fakturerad part'
          icon='fas fa-address-book'
          buttons={{
            header: [{ text: 'Gå till hyresgäst', icon: 'fas fa-address-book', link: `#!/tenant/${invoicebase.tenant.id}` }]
          }}>
          <div class='row mb-3'>
            <Output hidden={invoicebase.tenant.customer_number === null} value={invoicebase.tenant.customer_number} label='Kundnummer' />
            <Output value={invoicebase.tenant.name} label='Namn' />
          </div>
          <div class='row mb-3'>
            <Output value={invoicebase.tenant.email} label='E-post' />
            <Output value={invoicebase.tenant.phone} label='Telefonnummer' />
          </div>
        </Card>
      )}
      <div class='row'>
        <InvoiceRows fetch={() => getInvoicebase()} invoicebase={invoicebase} />
      </div>
      <div class='row'>
        <div class='col'>
          <Events id={invoicebase.id} type='invoicebase' refresh={refresh} />
        </div>
      </div>
      <Modal isOpen={generateModal} onRequestClose={() => setGenerateModal(false)} className='no-padding'>
        <Card
          title='Skapa hyresavi'
          icon='fas fa-file-invoice'
          buttons={{
            header: [{ callback: () => setGenerateModal(false), icon: 'fas fa-times', text: 'Avbryt', bg: 'light' }],
            footer: [<Submit onSubmit={handleSubmit(generateManual)} text='Skapa' sending={sending} />]
          }}>
          <div class='row mb-3'>
            <Input controls={{ register }} name='ocr' options={{ required: true }} label='OCR' error={error} />
          </div>
          <div class='row mb-3'>
            <Input controls={{ register }} name='reference' options={{ required: true }} label='Fakturanummer' error={error} />
          </div>
        </Card>
      </Modal>
      <Modal isOpen={dueAtModal} onRequestClose={() => setDueAtModal(false)} className='no-padding'>
        <Card
          title='Redigera Förfallodatum'
          icon='fas fa-fw fa-calendar'
          buttons={{
            header: [{ callback: () => setDueAtModal(false), icon: 'fas fa-times', text: 'Avbryt', bg: 'light' }],
            footer: [<Submit onSubmit={handleSubmit(changeDueAt)} text='Uppdatera' sending={sending} />]
          }}>
          <div class='row mb-3'>
            <Date label='Förfallodatum' controls={{ control }} name='due_at' error={error} />
          </div>
          {invoicebase.invoice_type === 'MANUAL' && (
            <div class='row'>
              <Select
                controls={{ register, setValue }}
                name='status'
                options={{ required: false }}
                label='Status'
                error={error}
                values={[
                  { value: 'REVIEW', key: 'Attestering' },
                  { value: 'READY', key: 'Redo att skickas' },
                  { value: 'SENT', key: 'Skickad' },
                  { value: 'PAYED', key: 'Betald' },
                  { value: 'PARTIALLY_PAYED', key: 'Delbetald' },
                  { value: 'OVERDUE', key: 'Förfallen' }
                ]}
              />
            </div>
          )}
        </Card>
      </Modal>
      <Modal isOpen={paymentModal} onRequestClose={() => setPaymentModal(false)} className='no-padding'>
        <Card
          title='Registrera betalning'
          icon='fas fa-fw fa-money-bill'
          buttons={{
            header: [{ callback: () => setPaymentModal(false), icon: 'fas fa-times', text: 'Avbryt', bg: 'light' }],
            footer: [<Submit onSubmit={handleSubmit(pay)} text='Spara' sending={sending} />]
          }}>
          <div class='row mb-3'>
            <Input controls={{ register }} name='payment' options={{ required: true }} label='Belopp' type='number' error={error} />
          </div>
        </Card>
      </Modal>
    </>
  )
}
