import React, { useEffect, useState } from 'react'
import { notify, integrationhelper } from '@/_helpers'
import { API, ViewHeader, Card, Table, Submit, Input, Help } from '@enkla-gui'
import { useForm } from 'react-hook-form'
import Modal from 'react-modal'

export default function Integration() {
	const { register, handleSubmit, reset } = useForm()
	const [integrations, setIntegrations] = useState(false)
	const [modal, setModal] = useState(false)
	const [sending, setSending] = useState(false)
	const [error, setError] = useState(null)


	const getIntegrations = () => {
		API.get('api/integration').then(integrations => setIntegrations(integrations))
	}
	useEffect(() => {
		getIntegrations()
	}, [])

	const remove = async integration => {
		if (!(await notify.confirm(`Vill du ta bort kopplingen för ${integrationhelper.name(integration.name)}?`))) return
		API.delete(`api/integration/${integration.id}`)
			.then(res => {
				notify.toast('Integration bortkopplad')
				getIntegrations()
			})
			.catch(([err, res]) => {
				notify.toast(res, 'error')
			})
	}

	const update = data => {
		setSending(true)
		API.patch(`api/integration`, data)
			.then(res => {
				notify.toast('Integration uppdaterad')
				setModal(false)
				setSending(false)
				getIntegrations(true)
			})
			.catch(([err, res]) => {
				notify.toast(res, 'error')
				setError({
					message: res.message,
					data: res.data
				})
				setSending(false)
			})
	}

	return (
		<>
			<ViewHeader
				icon='fa-plug'
				title='Integrationer'
				subtitle='Översikt'
			/>
			<Card loading={!integrations} title='Integrationer' icon='fas fa-plug'>
				<Table
					rows={integrations}
					empty={{ text: 'Du har inte kopplat någon integration', icon: 'fas fa-server' }}
					functions={{
						name: name => <span class='badge bg-success'>{name.charAt(0).toUpperCase() + name.toLowerCase().slice(1)} </span>,
						remove: integration => remove(integration),
						set: integration => {
							setModal(true)
							reset(integration)
						}
					}}
					keys={['_this>button:set:fa-edit', 'description', 'name>function:name', 'created_at>date:full', '_this>button:remove:fa-unlink']}>
					<th class='min' />
					<th>Beskrivning</th>
					<th class='min'>Namn</th>
					<th>Kopplad</th>
					<th class='min' />
				</Table>
			</Card>
			<Modal isOpen={modal} onRequestClose={() => setModal(false)} className='no-padding'>
				<Card
					title='Redigera Integration'
					icon='fas fa-fw fa-receipt'
					buttons={{
						header: [{ callback: () => setModal(false), icon: 'fas fa-times', text: 'Avbryt', bg: 'light' }],
						footer: [<Submit onSubmit={handleSubmit(update)} text='Uppdatera' sending={sending} />]
					}}>
					<div class='row mb-3'>
						<Input controls={{ register }} name='description' options={{ required: true }} label='Beskrivning' error={error} />
					</div>
				</Card>
			</Modal>
		</>
	)
}
