import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { ViewHeader, Table, Card, Output, useGet, useDelete } from '@enkla-gui'
import Modal from 'react-modal'
import { icons } from '@config'
import MaintenanceTable from '@components/maintenance/MaintenanceTable'

export default function Company() {
	let { id } = useParams()
	const history = useHistory()

	let [infoModal, setInfoModal] = useState(false)
	let { data: company } = useGet({ endpoint: `api/company/${id}` })

	let { remove } = useDelete({
		endpoint: `api/company/${id}`,
		confirm:
			company?.favouritechildren.length !== 0
				? `Om du raderar ett företag kommer alla objekt kopplade till företaget att raderas. Denna åtgärd kan inte återkallas.`
				: '',
		success: () => history.push('/company')
	})

	return (
		<>
			<ViewHeader
				loading={!company}
				icon={icons.company}
				title={company?.name}
				subtitle={company?.organisation_number}
				return={{ link: '#!/company', title: 'Företag' }}
				breadcrumbs={company?.breadcrumb}
				buttons={[
					{ icon: icons.email, title: 'Kontakta hyresgäster', href: `#!/contact/company/${company?.id}` },
					{ icon: icons.info, title: 'Information', callback: () => setInfoModal(true) },
					{ icon: icons.invoice, title: 'Aviseringsinställningar', href: `#!/company/${company?.id}/invoicingconfig` },
					{ icon: icons.remove, title: 'Radera', callback: remove, position: 'right' },
					{ icon: icons.edit, title: 'Redigera', href: `#!/company/${company?.id}/edit`, position: 'right' }
				]}
			/>

			<Modal isOpen={infoModal} onRequestClose={() => setInfoModal(false)} className='no-padding'>
				<Card loading={!company} title={company?.name} icon={icons.company}>
					<div class='row mb-3'>
						<Output value={company?.name} label='Namn' cols='col-6' />
						<Output value={company?.organisation_number} label='Organisationsnummer' cols='col-6' />
					</div>
					<div class='row mb-3'>
						<Output value={company?.address} label='Adress' cols='col-6' />
						<Output value={company?.postal_code} label='Postnummer' cols='col-6' />
					</div>
					<div class='row mb-3'>
						<Output value={company?.city} label='Stad' cols='col-6' />
						<Output value={company?.country} label='Land' cols='col-6' />
					</div>
					<div class='row mb-3'>
						<Output value={company?.phone} label='Telefonnummer' cols='col-6' />
						<Output value={company?.email} label='Epost-adress' cols='col-6' />
					</div>
				</Card>
			</Modal>
			{company && <MaintenanceTable id={company.id} type='company' />}
			<Card
				title='Objekt'
				icon={icons.entity}
				loading={!company}
				buttons={{
					header: [{ link: `#!/entity/create/company/${company?.id}`, icon: icons.create, text: 'Skapa' }]
				}}>
				<Table
					rows={company?.favouritechildren}
					link={`/#!/entity/@{id}`}
					functions={{
						getType: type => (
							<div class='icon-badge'>
								<i class={`fas fa-fw ${type.icon}`} /> <span>{type.name}</span>{' '}
							</div>
						)
					}}
					keys={['name', 'type>function:getType', 'children_count>label:light']}>
					<th>Namn</th>
					<th class='min'>Typ</th>
					<th class='min'>Underliggande Objekt</th>
				</Table>
			</Card>
		</>
	)
}
