import { useContext, useState } from "react";
import { UserContext } from '@/App'

export default function OnboardingButton() {
    const { tasks } = useContext(UserContext)
    const getCompletion = () => {
        if (!tasks) return
        var totaltasks = 0;
        var completedtasks = 0;
        for (var tg of tasks) {
            totaltasks += tg.tasks.length
            completedtasks += tg.tasks.filter(t => t.completed).length
        }
        return Math.floor((completedtasks / totaltasks) * 100);
    }
    if (!tasks) return null

    return (
        <a
            className="btn btn-toggle align-items-center sidebar-single-item rounded collapsed flex-grow btn-light d-flex"
            href={"#!/onboard"}
            style={{ display: "flex", alignItems: "center", margin: "0px 25px", marginBottom: "30px", justifyContent: "space-around", padding: "5px" }}
        >
            <span>Kom igång!</span>
            <div
                style={{
                    width: "36px",
                    height: "36px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                }}
            >
                {getCompletion() === 100 ? (
                    <i className="fas fa-check-circle" style={{ fontSize: "24px", color: "#4CAF50" }}></i>
                ) : (
                    <div style={{ position: "relative" }}>
                        <svg width="36" height="36" viewBox="0 0 36 36" style={{ transform: "rotate(-90deg)" }}>
                            <circle
                                cx="18"
                                cy="18"
                                r="16"
                                stroke="#ddd"
                                strokeWidth="4"
                                fill="none"
                            />
                            <circle
                                cx="18"
                                cy="18"
                                r="16"
                                stroke="#4CAF50"
                                strokeWidth="4"
                                fill="none"
                                strokeDasharray="100"
                                strokeDashoffset={100 - getCompletion()}
                                strokeLinecap="round"
                                style={{ transition: "stroke-dashoffset 0.5s ease" }}
                            />
                        </svg>
                        <span
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                fontSize: "12px",
                                fontWeight: "bold",
                                color: "#333",
                            }}
                        >
                            {getCompletion()}
                        </span>
                    </div>
                )}
            </div>
        </a>
    );
};

