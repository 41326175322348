import React from 'react'
import { ViewHeader, Card, Submit, Input, Select, BeefyButton, Help, useGet, useSubmit, CheckBox, Explination } from '@enkla-gui'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { icons } from '@config'
export default function Invoicing() {
  let { id } = useParams()

  const { data: config, fetch } = useGet({ endpoint: `api/company/${id}/invoicingconfig` })
  const { data: integrations } = useGet({ endpoint: 'api/integration/invoicing' })

  const { register, submit, setValue, watch, getValues, loading, error } = useSubmit({
    endpoint: `api/company/${id}/invoicingconfig`,
    form: config,
    method: 'patch',
    success: fetch
  })

  const getNextGenerateOccurance = () => {
    var content = []
    if ([null, ''].includes(watch('generate_day')))
      return (
        <p>
          Det kommer <b>inte</b> skapas några hyresavier automatiskt.
        </p>
      )
    var generate_day = parseInt(watch('generate_day'))
    var today = parseInt(moment().format('D'))
    var endOfMonth = parseInt(moment().endOf('month').format('D'))
    if (generate_day > endOfMonth) generate_day = endOfMonth

    content.push(
      <p>
        Nästa hyresavi kommer skapas den{' '}
        <b>
          {generate_day}
          {[1, 2].includes(generate_day) ? 'a' : 'e'} {today >= generate_day ? moment().add(1, 'M').format('MMMM') : moment().format('MMMM')}{' '}
        </b>
      </p>
    )
    if (watch('generate_period') !== null) {
      content.push(
        <p>
          {' '}
          Hyresavin avser perioden för{' '}
          <b>
            {watch('generate_period') === 'CURRENT_MONTH'
              ? moment()
                .add(today >= generate_day ? 1 : 0, 'M')
                .format('MMMM')
              : moment()
                .add(today >= generate_day ? 2 : 1, 'M')
                .format('MMMM')}{' '}
          </b>
          och framåt
        </p>
      )
    }
    return content
  }
  const getNextSendOccurance = () => {
    if ([null, ''].includes(watch('send_day')))
      return (
        <p>
          Det kommer <b>inte</b> skickas några hyresavier automatiskt.
        </p>
      )
    var send_day = parseInt(watch('send_day'))
    var today = parseInt(moment().format('D'))
    var endOfMonth = parseInt(moment().endOf('month').format('D'))
    if (send_day > endOfMonth) send_day = endOfMonth

    return (
      <p>
        Nästa automatiska utskick kommer att göras den{' '}
        <b>
          {send_day}
          {[1, 2].includes(send_day) ? 'a' : 'e'} {today >= send_day ? moment().add(1, 'M').format('MMMM') : moment().format('MMMM')}
        </b>
      </p>
    )
  }

  return (
    <>
      <ViewHeader
        loading={!config}
        icon={icons.invoice}
        title='Inställningar för hyresavisering'
        subtitle={config?.company.name}
        return={{
          link: `#!/company/${id}`,
          title: config?.company.name
        }}
      />
      <Card
        loading={!integrations}
        buttons={{
          footer: [<Submit onSubmit={submit} text='Spara' sending={loading} />]
        }}>
        <input type='hidden' {...register('company_id')} value={id} />
        <label class='cardlabel mb-3'>
          <i class={icons.invoice} /> Hyresavisering
        </label>
        <div class='row mb-3'>
          <Select
            placeholder='Manuell'
            controls={{ register, setValue, getValues }}
            name='integration_id'
            options={{ required: false }}
            tip="Vad innebär metod för hyresavier?"
            label='Metod för hyresavier'
            error={error}
            values={integrations?.map(integration => {
              return {
                key: `${integration.description} (${integration.name.charAt(0).toUpperCase() + integration.name.toLowerCase().slice(1)})`,
                value: integration.id
              }
            })}
          />
        </div>
        <div class='row mb-3'>
          <Select
            controls={{ register, setValue }}
            name='terms_of_payment'
            options={{ required: false }}
            label='Betalningsvillkor'
            error={error}
            noInitialValue
            values={[
              { key: 'Inaktivt', value: 'INACTIVE' },
              { key: '0 dagar', value: '0' },
              { key: '10 dagar', value: '10' },
              { key: '15 dagar', value: '15' },
              { key: '20 dagar', value: '20' },
              { key: '30 dagar', value: '30' }
            ]}
          />
          <div class='col'>
            <Input controls={{ register }} name='vat' options={{ required: false }} label='Momsnummer' error={error} />
          </div>
        </div>
        <div class='row mb-3'>
          <div class='col'>
            <Input controls={{ register }} name='plusgiro' options={{ required: false }} label='Plusgiro' error={error} />
          </div>
          <div class='col'>
            <Input controls={{ register }} name='bankgiro' options={{ required: false }} label='Bankgiro' error={error} />
          </div>
        </div>
        <div class='col'>
          <BeefyButton
            _default={{
              text: 'Ställ in automatisk hyresavisering',
              icon: icons.toggle(watch('auto_enabled'))
            }}
            hover={{
              text: 'Ställ in automatisk hyresavisering',
              icon: icons.toggle(watch('auto_enabled'))
            }}
            type={!watch('auto_enabled') ? 'info' : 'success'}
            callback={() => setValue('auto_enabled', !getValues('auto_enabled'))}
          />
        </div>
        {watch('auto_enabled') && (
          <>
            <hr class='mt-3' />
            <label class='cardlabel mb-3'>
              <i class='fas fa-sync-alt ' /> Automatisk hantering av hyresavier{' '}
            </label>
            <Help title='Information' type='info'>
              <p>
                Ange ett värde mellan <b>1-31</b>
              </p>
              <p>
                Månader som har färre än <b>31</b> dagar kommer hanteras på sin sista dag.
              </p>
              <p>
                Hyresavier kommer enbart skickas om dom har statusen <b>Redo att skickas</b>
              </p>
              <p>
                Efter att den skickats ändras statusen till <b>Skickad</b>
              </p>
            </Help>
            <div class='row mb-3'>
              <Input
                controls={{ register }}
                name='generate_day'
                placeholder='Inaktivt'
                options={{ required: false }}
                label='Hyresavier skall skapas automatiskt den'
                error={error}
                type='number'
              />
              <Input
                controls={{ register }}
                name='send_day'
                placeholder='Inaktivt'
                options={{ required: false }}
                label='Hyresavier skall skickas automatiskt den'
                error={error}
                type='number'
              />
            </div>
            <div class='row mb-3'>
              <div class='col-6'>
                <Select
                  controls={{ register, setValue }}
                  disabled={watch('generate_day') === null || watch('generate_day')?.length === 0}
                  name='generate_period'
                  options={{ required: false }}
                  label='Hyresavierna avser'
                  error={error}
                  values={[
                    { key: 'Nuvarande månad', value: 'CURRENT_MONTH' },
                    { key: 'Nästkommande månad', value: 'NEXT_MONTH' }
                  ]}
                />
              </div>
              <div class='col-6'>
                <div class='mb-3 form-group'>
                  <label>
                    Attestera hyresavier innan de skickas{' '}
                    <Explination tip='Hyresavierna måste godkännas innan de skickas iväg. Avier som kräver attestering samlas i menyn under Hyresavier > Attestering' />
                  </label>
                  <br />
                  <CheckBox controls={{ register, getValues, setValue }} name={`auto_require_review`} options={{ required: false }} label='Kräv attestering' />
                </div>
              </div>
            </div>
            <div class='row'>
              <Help header={false} icon='' temporary>
                {getNextGenerateOccurance()}
                {getNextSendOccurance()}
              </Help>
            </div>
          </>
        )}
      </Card>
    </>
  )
}
