import './Pipey.scss'

import React, { useContext, useEffect, useState } from 'react'

import { NoContentBox } from '@enkla-gui'
import { PipeySearchContext } from '@providers/PipeyProvider.js'
import Tippy from '@tippyjs/react'
import { UserContext } from '@/App'
import faq from './faq.json'
import { Drawer, FloatButton, Divider, Typography } from 'antd'
const { Title, Paragraph, } = Typography;


export default function Pipey() {
  const { search, setSearch } = useContext(PipeySearchContext)
  const [results, setResults] = useState(faq)
  const [active, setActive] = useState(false)
	const { user } = useContext(UserContext)
  const [tooltipAlwaysVisible, setTooltipAlwaysVisible] = useState(() => {
    return !localStorage.getItem("tooltipSeen")
  })

  useEffect(() => {
    if (search.trim().length === 0) return setResults(faq)
    dispatch(search)
    if (!active) setActive(true)
  }, [search])

  const dispatch = value => {
    const searchWords = value.toLowerCase().split(/\s+/)
    setResults(
      faq.filter(d =>
        searchWords.every(
          word =>
            d.a.toLowerCase().includes(word) ||
            d.q.toLowerCase().includes(word)
        )
      )
    )
  }

  const highlightText = text => {
    if (search === "") return <div dangerouslySetInnerHTML={{ __html: text }} />

    const regex = /(<[^>]+>|[^<]+)/g
    const parts = text.match(regex)

    const searchWords = search.split(/\s+/)
    const searchPattern = searchWords.map(word => `(${word})`).join("|")
    const regexSearch = new RegExp(searchPattern, "gi")

    const highlightedParts = parts.map((part, index) => {
      if (part.startsWith("<")) {
        return part
      } else {
        return part.replace(regexSearch, '<mark key=' + index + '>$&</mark>')
      }
    })

    const combined = highlightedParts.join("")
    return <div dangerouslySetInnerHTML={{ __html: combined }} />
  }

  const handleHelpClick = () => {
    if (tooltipAlwaysVisible) {
      localStorage.setItem("tooltipSeen", "true")
      setTooltipAlwaysVisible(false)
    }
    setActive(!active)
  }

  return (
    <>
		<Drawer
					placement='right'
					open={active}
					closable={true}
					onClose={e => setActive(false)}
					title='Frågor och svar'>
            <div className="form-group">
              <input
                value={search}
                type="text"
                className="form-control"
                placeholder="Sök..."
                onChange={e => setSearch(e.target.value)}
              />
            </div>
            <Divider />
            <div className="qas">
              {results.map((f, index) => (
								<>
									<Title level={4}>{highlightText(f.q)}</Title>
									<Paragraph>{highlightText(f.a)}</Paragraph>
									<Divider />
								</>
              ))}
              {results.length === 0 && <NoContentBox text="Din sökning gav inga träffar" />}
            </div>
				</Drawer>
				<Tippy
              content="Frågor? Klicka här för FAQ & tips."
							visible={tooltipAlwaysVisible && user.show_onboarding_in_sidebar ? true : false}
              placement="top-end">
					<FloatButton onClick={handleHelpClick} icon={<i className='fas fa-question' />} type="primary" />
				</Tippy>

    </>
  )
}
