import React, { useState, useEffect, useContext } from 'react'
import { date, cashmoneyhelper, notify } from '@/_helpers'
import { UserContext } from '@/App'
import { API, ViewHeader, Table, Card, Output, Help } from '@enkla-gui'

export default function Membership() {
  const [loading, setLoading] = useState(true)
  const [membership, setMembership] = useState(false)
  const { user } = useContext(UserContext)
  const [downloading, setDownloading] = useState(false)

  const cancelSubscription = async () => {
    if (!(await notify.confirm('Vill du säga upp ditt medlemsskap?'))) return
    setLoading(true)
    API.post('api/membership/cancel', {}).then(res => {
      API.get('api/membership/subscription', {}).then(res => {
        setMembership(res)
        setLoading(false)
      })
    })
  }
  const renewSubscription = () => {
    setLoading(true)
    API.post('api/membership/resume', {}).then(res => {
      API.get('api/membership/subscription', {}).then(res => {
        setMembership(res)
        setLoading(false)
      })
    }).catch(([err, res]) => {
      notify.toast('Något gick fel. Se till att du har lagt till en betalningsmetod och prova igen senare.', 'error')
      setLoading(false);
    })
  }

  useEffect(() => {
    API.get('api/membership/subscription', {}).then(res => {
      setMembership(res)
      setLoading(false)
    })
  }, [])

  const download = invoice => {
    setDownloading(true)
    API.blob(`api/membership/invoice/${invoice.id}`).then(blob => {
      var file = window.URL.createObjectURL(blob)
      window.open(file)
      setDownloading(false)
    })
  }

  return (
    <>
      <ViewHeader
        loading={!membership}
        icon='fa-money-check-alt'
        title='Inställningar'
        subtitle='Abonnemang'
        buttons={[
          {
            title: 'Hantera Betalningsmetoder',
            icon: 'fas fa-wallet',
            callback: () => window.open(membership.url)
          },
          {
            title: 'Säg upp ditt abonnemang',
            hidden: !membership.is_active_member || membership.is_canceled,
            icon: 'fas fa-times',
            callback: () => cancelSubscription()
          },
          {
            title: 'Återaktivera ditt abonnemang',
            hidden: membership.is_active_member && !membership.is_canceled,
            icon: 'fas fa-check',
            callback: () => renewSubscription()
          }
        ]}
      />
      <div class='row'>
        <div class='col' hidden={!membership}>
          {!membership.has_payment_method && membership.is_active_member && !membership.is_on_trial && (
            <Help temporary header={false}>
              <span>
                Du har ingen betalningsmetod kopplad till det här kontot, det betyder du kommer bli av med tillgång till systemet den{' '}
                <b>{date.short(membership.current_period_end)}</b>
              </span>
            </Help>
          )}
          {!membership.has_payment_method && membership.is_active_member && membership.is_on_trial && (
            <Help temporary header={false}>
              <span>
                Din kostnadsfria prövningsperiod kommer att ta slut <b>{date.short(membership.current_period_end)}</b>, lägg till en betalningsmetod ovan för
                att inte riskera att förlora tillgång till systemet.
              </span>
            </Help>
          )}
          {membership.is_canceled && (
            <Help temporary header={false} type='danger'>
              <span>
                Ditt abonnemang är uppsagt, du kommer förlora tillgång till systemet <b>{date.short(membership.current_period_end)}</b>.
              </span>
            </Help>
          )}
          {membership.is_past_due && (
            <Help temporary header={false}>
              <span>
                För att fortsätta använda systemet behöver du lägga till en betalningsmetod. Du har ingen bindningstid och kan avsluta när du vill.
              </span>
            </Help>
          )}
        </div>
      </div>
      <Card title='Abonnemang' icon='fab fa-fw fa-buffer' loading={loading || !membership}>
        <div class='row'>
          <div class='col'>
            <div class='mb-3'>
              <Output
                value={
                  membership.is_past_due ? 'Avstängt' : membership.is_canceled ? 'Uppsagt' : (membership.is_paying_member && membership.is_active_member) ? 'Aktivt' : membership.is_on_trial ? 'Kostnadsfri prövningsperiod' : ''
                }
                label='Status'
              />
            </div>
            <div class='mb-3'>
              <Output value={date.short(membership.current_period_end)} label='Nästa förfallodatum' />
            </div>
            <div class='mb-3'>
              <Output value={date.short(user.created_at)} label='Registreringsdatum' />
            </div>
          </div>
          <div class='col'>
            <Help type='info' title='Betalning' icon='fas fa-wallet'>
              <span>Enkla Fastigheter använder sig av Stripe som betalningsleverantör.</span>
              <br />
              <br />
              <span>
                För att lägga till eller redigera dina betalningsmetoder klicka på "Hantera Betalningsmetoder" så skickas du till Stripe's externa portal.
              </span>
            </Help>
          </div>
        </div>
      </Card>

      <Card title='Fakturor' icon='fas fa-file-invoice'>
        <Table
          rows={membership?.invoices}
          functions={{
            _download: invoice => (
              <button disabled={downloading} class='btn btn-sm btn-light' onClick={() => download(invoice)}>
                <i class={`fa fa-fw fa-${downloading ? 'circle-notch fa-spin' : 'download'}`} />
              </button>
            ),
            paid: invoice =>
              invoice.paid ? (
                <span class={`badge bg-success w-100`}>
                  <i class='fas fa-check'></i>
                </span>
              ) : (
                <span class={`badge bg-danger w-100`}>
                  <i class='fas fa-times'></i>
                </span>
              ),
            period: lines => {
              //love isnt always on time
              if (!lines.data || lines.data.length !== 1) return ''
              var period = lines.data[0].period;
              return date.short(period.start) + ' - ' + date.short(period.end)
            },
            total: invoice => cashmoneyhelper.format(invoice.total / 100)
          }}
          keys={[
            'created>date:short',
            'lines>function:period',
            '_this>function:total',
            '_this>function:paid',
            '_this>function:_download'
          ]}>
          <th>Skapad</th>
          <th>Period</th>
          <th>Belopp</th>
          <th class='min'>Betald</th>
          <th class='min'></th>
        </Table>
      </Card>
    </>
  )
}
