import React, { useState, useEffect, useContext } from 'react'
import { BehaviorSubject } from 'rxjs'
import Item from './Item'
import Collapsable from './Collapsable'
import { useHistory } from 'react-router-dom'
import OnboardingButton from '@components/layout/OnboardingButton'


const sidebarCollapsedObserver = new BehaviorSubject(false)

function Sidebar({ getBadges = false, title, logo, menu, showOnboarding = false }) {
	const [collapsed, setCollapsed] = useState(false)
	const [badges, setBadges] = useState(false)
	const [shouldClose, setShouldClose] = useState(false)

	const history = useHistory()
	useEffect(() => {
		sidebarCollapsedObserver.subscribe(x => setCollapsed(x))
	}, [collapsed])

	useEffect(() => {
		if (!getBadges) return
		getBadges().then(badges => setBadges(badges))
		return history.listen(() => {
			getBadges().then(badges => setBadges(badges))
		})
	}, [history])

	const mouseEnter = () => {
		if (!collapsed || window.innerWidth < 450) return
		sidebarCollapsedObserver.next(false)
		setShouldClose(true)
	}

	const mouseLeave = () => {
		if (!shouldClose || window.innerWidth < 450) return
		sidebarCollapsedObserver.next(true)
		setShouldClose(false)
	}
	return (
		<div
			class={`d-flex flex-column flex-shrink-0 component_sidebar ${collapsed ? (window.innerWidth < 450 ? 'mobile-display' : 'collapsed') : ''}`}
			onMouseEnter={() => mouseEnter()}
			onMouseLeave={() => mouseLeave()}>
			<a href='/' class='d-flex  mb-3 mb-md-0 ms-2 text-decoration-none sidebar-header'>
				<span class='fs-4 logo-wrapper'>
					<img src={logo} class='logo' alt='' />
					<small>{title}</small>
				</span>
			</a>
			<ul key='sidebar' class='list-unstyled sidebar-items-list ps-0 mb-auto'>
				{showOnboarding && (
					<OnboardingButton />
				)}
				{menu.map((item, x) => {
					switch (item.type) {
						case 'link':
							return <Item {...item} keyValue={`sidebar_link_${x}`} key={`sidebar_link_${x}`} />
						case 'collapsable':
							return <Collapsable badges={badges} collapsed={collapsed} {...item} keyValue={`sidebar_collapsable_${x}`} key={`sidebar_collapsable_${x}`} />
						default:
							return null
					}
				})}
			</ul>
		</div>
	)
}
export { Sidebar as default, sidebarCollapsedObserver }
