import { Card, InfoBox, useGet } from '@enkla-gui'
import React, { useContext, useEffect, useState } from 'react'
import { loader, notify } from '@/_helpers'
import { UserContext } from '@/App'
import Greeting from '@components/layout/Greeting'
import Modal from 'react-modal'

export default function Onboard() {
	const [activeGroup, setActiveGroup] = useState(null);
	const [selectedTask, setSelectedTask] = useState(false);
	const [show, setShow] = useState(false);
	const { task, setTask, tasks: taskgroup, refetchtasks } = useContext(UserContext)

	useEffect(() => {
		if (!taskgroup) return
		const found = taskgroup.find(group => group.tasks.some(task => !task.completed));
		setActiveGroup(found || taskgroup[0]);
	}, [taskgroup])
	useEffect(() => {
		refetchtasks();
		setTask(null)
	}, [])

	const onTaskClick = task => {
		if (task.locked) return notify.toast('Detta steg är låst tills att du utfört de tidigare stegen', 'info')

		if (typeof task.redirectOptions === 'object') {
			if (Object.entries(task.redirectOptions).length === 1) {
				setTask({ ...task, redirectOptions: Object.entries(task.redirectOptions)[0][1] })
				window.location.hash = Object.entries(task.redirectOptions)[0][1]
			} else {
				setSelectedTask(task)
			}
		} else {
			setTask({ ...task })
			window.location.hash = task.redirectOptions
		}
	}

	if (!taskgroup || activeGroup === null) return loader.content()
	//vet att den här är copy paste men det var in the heat of  the moment 4  maria
	const getAllCompletion = () => {
		if (!taskgroup) return
		var totaltasks = 0;
		var completedtasks = 0;
		for (var tg of taskgroup) {
			totaltasks += tg.tasks.length
			completedtasks += tg.tasks.filter(t => t.completed).length
		}
		return Math.floor((completedtasks / totaltasks) * 100);
	}
	const completed = `${Math.floor((activeGroup.tasks.filter(t => t.completed).length / activeGroup.tasks.length) * 100)}%`
	return (
		<>
			<Greeting greeting='Välkommen till Enkla Fastigheter, här hjälper vi dig komma igång.' />
			{getAllCompletion() === 100 && !show ?
				<div class="row">
					<div class="bicflex">
						<div>
							<p>Grattis! Du har nu gått igenom vår guide och tagit de första stegen för att komma igång. Vi hoppas att den har gett dig en tydlig bild av hur allt fungerar och att du känner dig redo att utforska vidare.</p>
							<p>Att komma igång kan ibland innebära en del nya begrepp och funktioner, men ingen stress – du lär dig i din egen takt. Om du stöter på något du är osäker på kan du alltid ta en titt i vår FAQ genom att klicka på frågetecknet nere till vänster. Där har vi samlat svar på vanliga frågor och praktiska tips.</p>
							<p>Skulle du behöva mer hjälp eller ha specifika frågor är du alltid välkommen att kontakta oss. Vi finns här för att se till att du får den bästa möjliga upplevelsen.</p>
							<p>Vill du kika på guiden igen ? <a class="btn-link" onClick={() => setShow(true)} >Klicka här för att visa den på nytt</a>.</p>
							<p>Vill du dölja knappen "Kom igång" i sidomenyn? <a class="btn-link" href="#/setting/user" >Klicka här</a>.</p>
							<div>
								<div class="bicflex">
									<img style={{ 'width': '50%', 'padding': '50px 0px' }} src='images/completed-task-7.svg' />
								</div>
							</div>
						</div>
					</div>
				</div>
				:
				<>
					<div class='row'>
						<div class='group-steps-container'>
							{taskgroup.map((group, i) => {
								const groupCompleted = group.tasks.filter(t => t.completed).length === group.tasks.length

								return (
									<div className={`group-step ${activeGroup.title === group.title ? 'group-step-active' : ''} ${groupCompleted ? 'group-step-completed' : ''}`} onClick={e => setActiveGroup(group)}>
										{groupCompleted ? <span><i class="fas fa-check" /> </span> :
											<span>{i + 1}</span>}
										<span>{group.title}</span>
									</div>
								)
							})}
						</div>
					</div >
					<div class="row taskgroup-info">
						<div class="taskgroup-desc" dangerouslySetInnerHTML={{ __html: activeGroup.description }} />
						<div class="col">
							<div class="progress">
								<div class={`progress-bar ${completed === '100%' && 'bg-success'}`} role="progressbar" style={{ width: completed }} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
									{completed}
								</div>
							</div>
						</div>
					</div>
					<div class="row no-margin">
						<div class="card no-padding">
							{activeGroup.tasks.map(task => (
								<div class="taskk-wrapper col-12" onClick={e => onTaskClick(task)}>
									<span class="fa-stack" style={{ fontSize: '1rem', verticalAlign: 'top' }}>
										<i class="fa-regular fas fa-circle fa-stack-2x"></i>
										<i class={task.icon + " fa-solid fa-stack-1x  fa-inverse"}></i>
									</span>
									<div>
										<span>{task.title}</span>
										<br />
										<small>{task.description}</small>
									</div>
									<span class="fa-stack" style={{ fontSize: '1rem', verticalAlign: 'top' }}>
										<i class={"fa-regular fas fa-circle fa-stack-2x " + (task.locked ? 'color-disabled' : task.completed ? 'color-success' : '')}></i>
										<i class={"fa-solid fa-stack-1x fa-inverse " + (task.locked ? 'fas fa-lock' : task.completed ? 'fas  fa-check' : 'fas fa-angle-right')} />
									</span>
								</div>
							))}
						</div>
					</div>
					<Modal isOpen={selectedTask} onRequestClose={() => setSelectedTask(false)} className='no-padding'>
						<Card title={selectedTask?.title} icon={selectedTask.icon}>
							{selectedTask !== false && (
								<ul class="list-unstyled" style={{ padding: '10px' }}>
									{Object.entries(selectedTask.redirectOptions).map(([key, value]) => (
										<li><a class="btn btn-primary w-100 mb-2" href={'#' + value}>{key}</a></li>
									))}
								</ul>
							)}
						</Card>
					</Modal>
				</>
			}
		</>
	)
}
