import React, { useEffect, useState } from 'react'
import { Timeline } from 'antd'
import { icons } from '@config'
import { useGetAll, Card, NoContentBox } from '@enkla-gui'
import moment from 'moment'

export default function UpcomingWeek() {
  const { loading: loadingMaintenance, data: maintenance } = useGetAll({ endpoint: `api/maintenance` })
  const { loading: loadingLeases, data: leases } = useGetAll({ endpoint: `api/lease` })
  const [events, setEvents] = useState([])
	const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!maintenance || !leases) return

    // Transform maintenance events
    const maintenanceEvents = maintenance.map(m => ({
      date: moment(m.scheduled_at * 1000),
      type: 'MAINTENANCE',
      title: m.title,
      entityName: m.entity?.name,
      entityUrl: `#!/entity/${m.entity?.id || ''}`
    }))

    // Transform lease events
    let leaseEvents = []
    leases.forEach(lease => {
      const tenant = lease.tenants?.[0]
      if (!tenant) return
      leaseEvents.push({
        date: moment(lease.start * 1000),
        type: 'LEASE_IN',
        tenantName: tenant.name,
        tenantUrl: `#!/tenant/${tenant.id}`,
        entityName: lease.entity?.name,
        entityUrl: `#!/entity/${lease.entity?.id || ''}`
      })
      leaseEvents.push({
        date: moment(lease.stop * 1000),
        type: 'LEASE_OUT',
        tenantName: tenant.name,
        tenantUrl: `#!/tenant/${tenant.id}`,
        entityName: lease.entity?.name,
        entityUrl: `#!/entity/${lease.entity?.id || ''}`
      })
    })

    // Combine and filter events
    let allEvents = [...maintenanceEvents, ...leaseEvents]
    const now = moment().startOf('day')
    const end = moment().add(7, 'days').endOf('day')
    allEvents = allEvents.filter(e => e.date.isBetween(now, end, null, '[]'))
    allEvents.sort((a, b) => a.date.valueOf() - b.date.valueOf())

    setEvents(allEvents)
  }, [maintenance, leases])

  // Helper to render bold links
  const renderLink = (url, text) => (
    <a href={url} style={{ fontWeight: 'bold' }}>
      {text}
    </a>
  )

  // Build timeline items with day headers
  const timelineItems = []
  let lastDay = null

  events.forEach(event => {
    const dayKey = event.date.format('YYYY-MM-DD')
    // Add a header for a new day
    if (dayKey !== lastDay) {
      timelineItems.push(
        <Timeline.Item key={`day-${dayKey}`} dot={null} color="gray">
          <strong>{event.date.format('dddd Do MMMM')}</strong>
        </Timeline.Item>
      )
      lastDay = dayKey
    }

    // Determine icon and color based on event type
    let icon, color, iconClass
		switch (event.type) {
			case 'LEASE_IN':
				iconClass = 'fa-house-user';
				color = 'text-success';
				break;
			case 'LEASE_OUT':
				iconClass = 'fa-door-open';
				color = 'text-danger';
				break;
			case 'MAINTENANCE':
				iconClass = 'fa-tools';
				color = 'text-primary';
				break;
			default:
				iconClass = 'fa-info-circle';
				color = 'gray';
		}

		// Using Font Awesome's stacking classes
		icon = (
			<span className="fa-stack fa-lg">
				<i className={`fas fa-circle fa-stack-1x ${color}`}></i>
				<i className={`fas ${iconClass} fa-stack-1x`} style={{ color: 'white', fontSize: 10 }}></i>
			</span>
		)

    timelineItems.push(
      <Timeline.Item
        key={`${event.type}-${event.date.valueOf()}-${event.tenantName || event.title}`}
        color={color}
        dot={icon}
      >
        {event.type === 'LEASE_IN' && (
          <>
            Hyresgäst {renderLink(event.tenantUrl, event.tenantName)} flyttar in i{' '}
            {renderLink(event.entityUrl, event.entityName)}
          </>
        )}
        {event.type === 'LEASE_OUT' && (
          <>
            Hyresgäst {renderLink(event.tenantUrl, event.tenantName)} flyttar ut från{' '}
            {renderLink(event.entityUrl, event.entityName)}
          </>
        )}
        {event.type === 'MAINTENANCE' && (
          <>
            Underhållsarbete: {event.title} ska utföras på{' '}
            {renderLink(event.entityUrl, event.entityName)}
          </>
        )}
      </Timeline.Item>
    )

  })

  return (
    <Card
      title="Din kommande vecka"
      icon={icons.calendar}
			loading={loadingLeases || loadingMaintenance}
    >
      {timelineItems.length === 0 ? (
        <NoContentBox text='Inga händelser är planerade den kommande veckan.' icon='fas fa-check' />
      ) : (
        <Timeline mode='left' className='ms-1'>{timelineItems}</Timeline>
      )}
    </Card>
  )
}
